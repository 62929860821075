import React, { ChangeEvent, ClipboardEvent, useCallback, useEffect, useState } from 'react'
import { Form, Input } from 'antd'
import AppPrimaryButton from '../../../../../components/UI/AppPrimaryButton/AppPrimaryButton'
import { useErrorMessage } from '../../../../../hooks/useErrorMessage'
import { useSuccessMessage } from '../../../../../hooks/useSuccessMessage'
import { useWarningMessage } from '../../../../../hooks/useWarningMessage'
import { requiredValidator } from '../../../../../helpers/validators'
import { useEditPromptMutation, useGetPromptQuery } from '../../../../../store/api/prompt.api'
import Loading from '../../../../../components/UI/Loading'

const QuizPromptTab = () => {
  const [formAddData] = Form.useForm()
  const quizPrompt = Form.useWatch('quizPrompt', formAddData)

  const { data, isLoading } = useGetPromptQuery()

  /** Storage Actions */
  const [editPromptRecord, editPromptRecordMutationResult] = useEditPromptMutation()

  /** State */
  const [newPromptRecord, setNewPromptRecord] = useState<string>('')

  /** Response message handlers */
  useErrorMessage('Something went wrong', editPromptRecordMutationResult.error)
  useSuccessMessage(
    'You data was successfully saved. Please check below and adjust if needed.',
    editPromptRecordMutationResult.isSuccess && !!editPromptRecordMutationResult.data?.quizPrompt,
  )
  useWarningMessage(
    'Seems like we were not able to parse your content. Please try again with more data.',
    editPromptRecordMutationResult.isSuccess && !editPromptRecordMutationResult.data?.quizPrompt,
  )

  const handlePaste = (event: ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault()

    const regExp = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/
    const pastedPlainText = event.clipboardData.getData('text/plain')
    const concatValue = newPromptRecord.concat(pastedPlainText)

    formAddData.setFieldsValue({ quizPrompt: concatValue })

    let requestValue = concatValue

    if (regExp.test(pastedPlainText)) {
      requestValue = newPromptRecord.concat(pastedPlainText)
    }

    setNewPromptRecord(requestValue)
  }

  const onFinishDataUpdate = async () => {
    const result = await editPromptRecord({
      body: { quizPrompt },
    })

    if (result) {
      formAddData.setFieldsValue({ quizPrompt })
    }

    setNewPromptRecord('')
  }

  useEffect(() => {
    if (editPromptRecordMutationResult.status === 'fulfilled') {
      formAddData.resetFields()
    }
  }, [editPromptRecordMutationResult.status])

  const onPromptChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setNewPromptRecord(e.target.value)
  }, [])

  if (isLoading) {
    return (
      <div className="loader-container">
        <Loading />
      </div>
    )
  }

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      form={formAddData}
      initialValues={{ quizPrompt: data?.quizPrompt }}
      onFinish={onFinishDataUpdate}
      disabled={editPromptRecordMutationResult.status === 'pending'}
    >
      <div className="form-ai-helper-col-wrapper form-ai-helper-col-wrapper-align-left">
        <h3>Paste quiz prompt text here</h3>
        <Form.Item
          name="quizPrompt"
          rules={[
            { min: 10, message: 'Too short data. Please paste at least 10 characters.' },
            { required: true, validator: requiredValidator('Data is Required!') },
          ]}
        >
          <Input.TextArea
            autoFocus={true}
            id="form-input"
            autoSize={{ minRows: 10, maxRows: 20 }}
            onPaste={handlePaste}
            onChange={onPromptChange}
          />
        </Form.Item>
        <div className="search-buttons-container">
          <Form.Item>
            <AppPrimaryButton
              type="primary"
              size="middle"
              htmlType="submit"
              className="app-primary-button-medium"
              loading={editPromptRecordMutationResult.status === 'pending'}
            >
              Save changes
            </AppPrimaryButton>
          </Form.Item>
        </div>
      </div>
    </Form>
  )
}

export default QuizPromptTab
