import { createApi } from '@reduxjs/toolkit/query/react'
import { LocalStorage } from 'ts-localstorage'
import baseQueryWithReauth from '../baseQueryWithReauth'
import { ACCESS_TOKEN } from '../../config/consts'
import { EditPromptRequest, Prompt } from '../../types/prompt.type'

export const promptApi = createApi({
  reducerPath: 'promptApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getPrompt: builder.query<Prompt, void>({
      query: () => ({
        url: `prompts`,
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          authorization: 'Bearer ' + <string>LocalStorage.getItem(ACCESS_TOKEN),
        },
      }),
    }),
    editPrompt: builder.mutation<Prompt, EditPromptRequest>({
      query: ({ body }) => ({
        url: `prompts`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
})

export const {
  useGetPromptQuery,
  useEditPromptMutation,
} = promptApi
