import React from 'react'
import { Tabs, TabsProps } from 'antd'
import AiHelperHeader from '../../components/AIHelperHeader/aiHelperHeader'
import './prompts.less'
import SystemPromptTab from './components/tabs/SystemPromptTab/SystemPromptTab'
import QuizPromptTab from './components/tabs/QuizPromptTab/QuizPromptTab'
const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'System Q&A',
    children: <SystemPromptTab />,
  },
  {
    key: '2',
    label: 'Quiz',
    children: <QuizPromptTab />,
  },
]

const Prompts = () => {

  return (
    <>
      <AiHelperHeader title="Prompts" paragraph="Configure your AI prompts for two main types: Q&A and Quiz for the LLM. Fine-tune instructions, context, and parameters to optimize responses." />
      <div id="container">
        <Tabs defaultActiveKey="1" items={items} destroyInactiveTabPane />
      </div>
    </>
  )
}

export default Prompts
